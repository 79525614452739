import React, { Component } from "react"

import Navigation from "../components/Navigation/Navigation"
import Footer from "../components/Footer/Footer"
import FormButton from "../components/Form/FormButton/FormButton"

import downIcon from '../images/down-arrow.svg'

import styles from '../styles/split-page.module.css'
import volunteerStyles from '../styles/volunteer.module.css'
import FormSuccess from "../components/Form/FormSuccess/FormSuccess"
import { getEmptyRequiredInputs, getInputs, submitInputs } from "../utils/General"
import Header from "../components/Header/Header"

class Volunteer extends Component {
    state = {
        name: '',
        email: '',
        number: '',
        country: '',
        state: '',
        success: false,
        inputs: [
            {
                label: 'Name',
                id: 'name'
            },
            {
                label: 'Email',
                id: 'email'
            },
            {
                label: 'Phone Number',
                id: 'number'
            },
            {
                label: 'Country',
                id: 'country',
                required: false
            },
            {
                label: 'State',
                id: 'state',
                required: false
            }
        ]
    }

    form = React.createRef()

    changed = (id,value) => {
        this.setState({
            [id]: value
        })
    }

    submit = () => {
        const [processedInputs,error] = getEmptyRequiredInputs(this.state)
        if(error){
            this.setState({processedInputs})
            return false;
        }else{
            submitInputs(processedInputs,'volunteer').then(res => {
                if(res){
                    this.setState({success: true})
                }else{
                    console.log('err')
                }
            })
            return true
        }
    }

    render() {
        return (
            <React.Fragment>
                <Header title={'Volunteer | BDEI'}/>
                <div className={styles.container}>
                    <Navigation dark absolute/>
                    <div className={`${styles.text} ${styles.textVolunteer}`}>
                        <div className={styles.text__backdrop}/>
                        <h1 className={`${styles.text__header} ${volunteerStyles.text__header}`}>
                            Hello Friend,
                            Register Today.
                        </h1>
                        <p className={`${styles.text__subtext} ${volunteerStyles.text__subtext}`}>
                            Volunteers are critical to the actualization of our goals for BDEI. A big part of our success is ascribed to the selfless service of our volunteers.
                        </p>
                        <p className={volunteerStyles.below}>
                            Kindly fill the form {' '}
                            <span
                                className={volunteerStyles.below__btn}
                                onClick={() => this.form.current.scrollIntoView({behavior: 'smooth'})}
                            >
                                below
                                <img src={downIcon} style={{width: '8px',marginLeft: '3px'}} alt=""/>
                            </span>
                        </p>
                    </div>
                    {
                        !this.state.success ? (
                            <div className={`${volunteerStyles.formContainer}`} ref={this.form} id="form">
                                <form>
                                    <h1 className={styles.form__header}>Volunteer with us</h1>
                                    {
                                        getInputs(this.state,this.changed)
                                    }
                                    <FormButton
                                        style={{width: '127px'}}
                                        submit={this.submit}
                                    >
                                        Submit
                                    </FormButton>
                                </form>
                            </div>
                        ) : <FormSuccess/>
                    }
                    <Footer absolute dark darktext/>
                </div>
            </React.Fragment>
        )
    }
}

export default Volunteer